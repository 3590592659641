import { $, html } from "@utils/dom";
import { on, off } from "@utils/listener";
import Viewport from "@utils/viewport";
import { moduleDelays } from "./utils";

const SELECTOR = "[data-site-loader]";

class SiteLoader {
  constructor() {
    this.el = $(SELECTOR);
    this.bg = $('.site-loader__bg', this.el);
    this.percee = $('.site-loader__percee', this.el);

    this._loadedPromise = null;
    this._readyPromise = null;
    this._slideInCompleted = false;

    this._onAnimationSlideInCompleted = this._onAnimationSlideInCompleted.bind(this);
    this._onBgAnimationCompleted = this._onBgAnimationCompleted.bind(this);
  }

  init() {
    on(this.percee, 'animationend', this._onAnimationSlideInCompleted);
  }
  loaded() {
    return new Promise(resolve => {
      // this._baseDelay = Viewport.width >= 1024 ? 550 : 800;
      this._baseDelay = 650;

      moduleDelays(350, this._baseDelay);

      this._loadedPromise = resolve;
      if( this._slideInCompleted ) resolve();
    });
  }

  ready() {
    return new Promise(resolve => {
      this._readyPromise = resolve;

      on(this.bg, 'animationend', this._onBgAnimationCompleted);
      this.el.classList.add('--exit');

      // wait for baseDelay then add class on html
      // setTimeout(() => { html.classList.add("--js-ready") }, this._baseDelay);
      html.classList.add("--js-ready");
    });
  }

  _onAnimationSlideInCompleted(event) {
    if( event.animationName !== "SiteLoader_slideIn" ) return;

    off(this.percee, 'animationend', this._onAnimationSlideInCompleted);
    this._slideInCompleted = true;

    if( this._loadedPromise ) this._loadedPromise();
  }
  _onBgAnimationCompleted() {
    off(this.bg, 'animationend', this._onBgAnimationCompleted);

    // remove from DOM when completed
    this.el.parentNode.removeChild(this.el);

    // resolve promise
    this._readyPromise();
  }
}

export default SiteLoader;
